import {registerStart, registerSuccess, registerFailure, loginStart, loginSuccess, loginFailure, logOut} from "./userRedux";
import { publicRequest } from "../requests";

export const register = async (dispatch, user) =>{
    dispatch(registerStart());
    try{
            const res = await publicRequest.post("/auth/register", user);
            console.log(user);
            dispatch(registerSuccess(res.data));
    }catch(err){
        dispatch(registerFailure())
    }
};


export const login = async (dispatch, user) =>{
    dispatch(loginStart());
    try{
            const res = await publicRequest.post("/auth/login", user);
            dispatch(loginSuccess(res.data));
            window.location.href = "/";
    }catch(err){
        dispatch(loginFailure())
    }
};

export const logout = (dispatch) =>{
    dispatch({type: "LOGOUT"}, logOut());
};