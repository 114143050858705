import { FavoriteBorderOutlined, SearchOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { useState } from 'react';
import { publicRequest } from "../requests";
import { useSelector } from "react-redux";
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';


const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
`;

const Container = styled.div`
    flex: 1;
    margin: 5px;
    min-width: 280px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
    &:hover ${Info}{
        opacity: 1;
    }
`;

const Circle = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
`;
const Image = styled.img`
    height: 75%;
    width: 260px;
    z-index: 2;
    border-radius: 50%;
`;
const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover{
        background-color: #e9f5f5;
        transform: scale(1.1);
    }
`;



const Product = ({item, wishlist}) => {

    const userId = useSelector((state)=> state.user.currentUser && state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser && state.user.currentUser.access_token);
    const productId = item._id;
    const [wishlistIconStatus, setWishlistIconStatus] = useState(false);

    const addToWishlist = async ()=> {
        try{
            const res = await publicRequest.post("/wishlist/", {userId, productId}, {headers: {'access-token': access_token}});
            if (res.status === 200){
                setWishlistIconStatus(true);
            }
        }catch{}
    };

const handleWishlistRequest = (e) => {
    addToWishlist();
}

    return (
        <Container>
            <Circle/>
            <Image src={item.image}/>
            <Info>
                <Icon onClick={()=> {document.location.href=`/product/${item._id}/`}}>
                    <SearchOutlined/>
                </Icon>
                {userId && <Icon onClick={()=> handleWishlistRequest()}>
                    { wishlistIconStatus || wishlist.includes(item._id) ? <FavoriteOutlinedIcon color="error"/> : <FavoriteBorderOutlined color="secondary"/> }
                </Icon>}
            </Info>
        </Container>
    )
}

export default Product
