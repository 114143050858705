import styled from "styled-components";
import { useState, useEffect } from 'react';
import { publicRequest } from "../requests";
import { ShoppingCartOutlined } from '@material-ui/icons';

const Container = styled.div`
    height: 30px;
    background-color: teal;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
`;


const Announcement = () => {


    const [banner, setBanner] = useState("");

    useEffect(()=>{
        const getBanner = async ()=>{
            try{
                const res = await publicRequest.get("/banner/",);
                setBanner(res.data.response.banner); 
            }catch(err){
    
            }
        };
        getBanner();
    }, [])

    return (
        <Container>
            <ShoppingCartOutlined/>&nbsp;&nbsp;{banner}
        </Container>
    )
}

export default Announcement
