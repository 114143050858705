import styled from "styled-components"
import { device } from "../adjust";
import { useState } from 'react';
import { register } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";



const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: url("");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 33%;
    padding: 20px;
    background-color: white;
    ${device({ width: "75%" })}
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 300;
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
`;

const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 10px;
`;

const Agreement = styled.span`
    font-size: 12px;
    margin: 20px 0px;
`;

const Button = styled.button`
    width: 40%;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
`;

const Error = styled.span`
    color: #a1140d;
    `;

const Message = styled.span`
    color: green;
    `;

const Anchor = styled.a`
    
`;

    const Register = () => {
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setcPassword] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    let errorStatus = "Registeration failed. Username/E-mail already exists.";
    const dispatch = useDispatch();
    const {regFetching, regError, regSuccess} = useSelector((state) => state.user);
    
    const handleClick = (e) => {
        e.preventDefault();
        register(dispatch, {fName, lName, password, username, email, cPassword});
    }
    return (
        <Container>
            <Wrapper>
                <Title>
                    Create an Account
                </Title>
                <Form>
                    <Input placeholder="name" onChange={(e)=> setfName(e.target.value)}/>
                    <Input placeholder="last name" onChange={(e)=> setlName(e.target.value)}/>
                    <Input placeholder="username" onChange={(e)=> setUsername(e.target.value)}/>
                    <Input placeholder="email" onChange={(e)=> setEmail(e.target.value)}/>
                    <Input placeholder="password" type="password" onChange={(e)=> setPassword(e.target.value)}/>
                    <Input placeholder="confirm password" type="password" onChange={(e)=> setcPassword(e.target.value)}/>
                    <Agreement>
                        By creating an account , I consent to KartFlip's <b>terms and conditions</b> 
                    </Agreement>
                    <Button onClick={handleClick} disable={regFetching}>Sign Up</Button>
                </Form>
                {regError && <Error>{errorStatus}</Error>}
                {regSuccess && <Message>Registeration success. Click <Anchor href="/signin">here</Anchor> to sign in now.</Message>}
            </Wrapper>
        </Container>
    )
}

export default Register
