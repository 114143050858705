import { useEffect, useState } from "react";
import { Redirect, useLocation, Link } from "react-router-dom";
import styled from "styled-components"

const Container = styled.div`
    margin: 20px;
    color: teal;
    font-weight: 500;
    background-color: #f5fafd;
`;

const Success = () => {

    const location = useLocation();
    const [redirect, setRedirect] = useState(false);
    setTimeout(() => setRedirect(true), 5000);

    useEffect(()=> {
    }, [location]);

    return (
        <Container>
            Thank you for your purchase! Redirecting... If you're not automatically redirected within 5 seconds, click
            <Link to="/orders/" style={{ color: 'inherit', textDecoration: 'inherit'}}> here!</Link>
            {redirect && <Redirect to="/orders/"/>}
        </Container>
    )
}

export default Success