import styled from 'styled-components'
import { device } from '../adjust';
import { categories } from "../data";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
    ${device({ padding: "0px", flexDirection: "column" })}
`;
const Categories = () => {
    return (
        <Container>
            {categories.map(item=>(
                <CategoryItem item = {item} key={item.id}/>
            ))}
        </Container>
    )
};

export default Categories
