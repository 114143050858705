import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { device } from "../adjust";
import { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { useLocation } from "react-router-dom";

const Container = styled.div``;
const Title = styled.h2`
    margin: 20px;
    font-weight: 300;
    font-size: 22px;
    margin-right: 20px; 
    ${device({ marginRight: "0px" })}
`;
const ResultContainer = styled.div`
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
`;
const SearchHeader = styled.div`
    margin-left: 20px;
    margin-bottom: 60px;
    margin-top: 60px;
    ${device({ width: "0px 20px", display: "flex", flexDirection: "column"})};
`;

const Search = () => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = params.get('q');

    useEffect(()=> {
        window.scrollTo(0, 0);
        });

    return (
        <Container>
            <Navbar/>
            <Announcement/>
            <SearchHeader>
            <Title>We  couldn't find any match for your query: <span dangerouslySetInnerHTML={{"__html": '"' + decodeURIComponent(query) + '"'}}/>. Here are some recommendations: </Title>
            </SearchHeader>
            <Divider orientation="horizontal" flexItem />
            <ResultContainer>
            <Products/>
            </ResultContainer>
            <Newsletter/>
            <Footer/>
        </Container>
    )
}

export default Search
