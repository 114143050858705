import axios from "axios";

const URL = "/api/";
const token = "";
export const publicRequest = axios.create({
    baseURL: URL,
});

export const userRequest = axios.create({
    baseURL: URL,
    header: {"access-token": token}
})