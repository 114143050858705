import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import React from "react";
import Success from "./pages/Success";
import Wishlist from "./pages/Wishlist";
import Myaccount from "./pages/Myaccount";
import Duperkoin from "./pages/Duperkoin";
import Search from "./pages/Search";
import { useEffect } from 'react';


import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom";
import { useSelector } from "react-redux";
import Orders from "./pages/Orders";
import Admin from "./pages/Admin";

const App = () => {
  useEffect(() =>{
    document.title="KartFlip, your secure shopping destination!"
  }, [])
  const user = useSelector((state)=> state.user.currentUser);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/account">
        {!user ? <Redirect to="/"/> : <Myaccount />}
        </Route>
        <Route exact path="/orders">
        {!user ? <Redirect to="/"/> : <Orders />}
        </Route>
        <Route exact path="/wishlist">
        {!user ? <Redirect to="/"/> : <Wishlist />}
        </Route>
        <Route path="/products/:category">
           <ProductList/>
        </Route>
        <Route path="/product/:id">
          <Product/>
        </Route>
        <Route path="/cart">
          {!user ? <Redirect to="/"/> : <Cart/>}
         </Route> 
        <Route exact path="/wallet">
          {!user ? <Redirect to="/"/> : <Duperkoin />}
        </Route>
        <Route path="/signin">
        {user ? <Redirect to="/"/> : <Login/>}
         </Route> 
        <Route path="/signup">
        {user ? <Redirect to="/"/> : <Register/>}
        </Route>
        <Route path="/success">
          <Success/>
        </Route>
        <Route path="/admin">
        {!user && <Redirect to="/"/>}
        {user && !user.isAdmin ? <Redirect to="/"/>: <Admin/>}
        </Route>
        <Route path="/search">
          <Search />
        </Route>
      </Switch>
    </Router>
  )
};

export default App;