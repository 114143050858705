import styled from "styled-components"
import { device } from "../adjust";
import { login } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: url("");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 25%;
    padding: 20px;
    background-color: white;
    ${device({ width: "75%" })}
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 300;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0;
    padding: 10px;
`;

const Button = styled.button`
    width: 40%;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    &:disabled{
        color: green;
        cursor: not-allowed;
    }
`;

const Error = styled.span`
    color: #a1140d;
    `;
const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const {isFetching, error} = useSelector((state) => state.user);

    const handleClick = (e) => {
        e.preventDefault();
        login(dispatch, {username, password});
    }
    return (
        <Container>
            <Wrapper>
                <Title>
                    Sign In
                </Title>
                <Form>
                    <Input placeholder="username" onChange={(e)=> setUsername(e.target.value)}/>
                    <Input placeholder="password" type="password" onChange={(e)=> setPassword(e.target.value)}/>
                    <Button onClick={handleClick} disable={isFetching}>Sign In</Button>
                    {error && <Error>Invalid credentials</Error>}
                </Form>
            </Wrapper>
        </Container>
    )
}

export default Login
