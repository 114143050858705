import { createSlice } from "@reduxjs/toolkit";
import { publicRequest } from "../requests";

const userSlice = createSlice({
    name: "user",
    initialState:{
        currentUser: null,
        imageUrl: null,
        koinBalance: 0,
        isFetching: false,
        regFetching: false,
        regError: false,
        error: false,
        regSuccess: false,
    },
    reducers: {
        loginStart: (state)=> {
            state.isFetching = true;
        },
        loginSuccess: (state, action)=> {
            state.isFetching = false;
            state.currentUser = action.payload;
            state.imageUrl = action.payload.image_url;
            state.koinBalance += action.payload.balance;
        },
        loginFailure: (state)=> {
            state.isFetching = false;
            state.error = true;
        },
        logOut: (state)=> {
            state.currentUser = null;
            state.imageUrl = null;
            state.koinBalance = 0;
        },
        registerStart: (state)=> {
            state.regFetching = true;
        },
        registerSuccess: (state, action)=> {
            state.regFetching = false;
            state.regError = false;
            state.regSuccess = true;
        },
        registerFailure: (state)=> {
            state.regFetching = false;
            state.regSuccess = false;
            state.regError = true;
        },
        changeProfilePic: (state, action) => {
            state.imageUrl = action.payload.profilePic;
        },
        refreshUserBalance: (state, action) => {
            state.koinBalance = action.payload.balance
        },
        resetCart: async (state) => {
            await publicRequest.post("/cart/reset", {
                userId: state.currentUser.user_id,
            }, {headers: {'access-token': state.currentUser.access_token}});
        }
    }
});

export const { registerStart, registerSuccess, registerFailure, loginStart, loginSuccess, loginFailure, logOut, changeProfilePic, refreshUserBalance, resetCart} = userSlice.actions;
export default userSlice.reducer;