import { useState, useEffect } from 'react';
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from '../components/Announcement'
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';
import { publicRequest } from '../requests';
import { useSelector } from "react-redux";
import OrderList from "../components/OrderList";
import LocalMallIcon from '@mui/icons-material/LocalMall';


const Div = styled.div`
`;

const Container = styled.div`
    width: 100vw;
    height: %;
    background: url("");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
`;

const Title = styled.h1`
    margin: 30px;
    padding: 20px;
    font-size: 24px;
    font-weight: 300;
`;

const ProductWrapper = styled.div`
    border: ${props=>props.type==="fill" ? "0.5px solid lightgray" : "none"};
    border-radius: 10px;
    padding: 20px;
    margin: 30px;
    height: 680px;
    width: 75vw;
    background-color: white;
    display: flex;
    overflow-y: auto;
`;

const Info = styled.div`
    flex: 3;
`;

const Emptiness = styled.div`
    font-weight: 350;
    display: flex;
    justify-content: center;
    color: gray;
`;


const Orders = () => {

    const user_id = useSelector((state)=> state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser.access_token);
    const [products, setProducts] = useState([]);
    const [orderBorder, setOrderBorder] = useState("");

    useEffect(()=>{
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get("/orders/fetch/" + user_id, {headers: {'access-token': access_token}});
                setProducts(res.data.response);
                if (res.data.response.length > 0) {
                    setOrderBorder("fill");
                };
            }catch(err){
            }
        };
        getProducts()
    }, [access_token, user_id]);

    return (
        <Div>
            <Announcement/>
            <Navbar/>
            <Container>
             <Title>
                Your Orders <LocalMallIcon fontSize="medium"/>
            </Title>
            <ProductWrapper type={orderBorder}>
            <Info>
                {products.length === 0 ? <Emptiness>You haven't placed any orders yet</Emptiness>: null}
                {products && products.map((item)=> (
                    item.products.map((element, index) => 
                    (<OrderList item={element} key={index}></OrderList>)
                )))}
            </Info>
            </ProductWrapper>
            </Container>
            <Newsletter/>
            <Footer/>
        </Div>
    )
}

export default Orders
