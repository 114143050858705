import styled from "styled-components";
import { publicRequest } from "../requests";
import { useState, useEffect } from 'react';

const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
`;

const Container = styled.div`
    flex: 1;
    margin: 5px;
    min-width: 280px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
    &:hover ${Info}{
        opacity: 1;
    }
`;

const Circle = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
`;
const Image = styled.img`
    height: 75%;
    width: 260px;
    z-index: 2;
    border-radius: 50%;
`;

const WishlistItem = ({item}) => {

    const [image, setImage] = useState("");

    useEffect(()=>{
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get("/products/find/" + item);
                setImage(res.data.response.image);
            }catch{}
        };
        getProducts()
    }, [item])

    return (
        <Container onClick={()=> {document.location.href=`/product/${item}`}}>
            <Circle/>
            <Image src={image}/>
            <Info>
            </Info>
        </Container>
    )
}

export default WishlistItem
