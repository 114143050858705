import { Badge } from '@material-ui/core';
import { Search, ShoppingCartOutlined } from '@material-ui/icons';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import styled from 'styled-components';
import { device } from '../adjust';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/apiCalls";
import { clearCart } from "../redux/cartRedux";
import { resetCart } from "../redux/userRedux";


const Container = styled.div`
    height: 60px;
    background-color: white;
    ${device({ height: "50px" })}

`;

const Wrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${device({ padding: "10px 0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const Language = styled.span`
    font-size: 14px;
    cursor: pointer;
    ${device({ display: "none" })}
`;

const SearchContainer = styled.div`
    border: 0.5px solid lightgray;
    display: flex;
    align-items: center;
    margin-left: 25px;
    padding: 5px;
    ${device({ paddingLeft: "3px" })}
`;

const Input = styled.input`
    outline: none;
    border: none;
    padding: 5px 20px;
    ${device({ width: "50px" })}
`;

const Center = styled.div`
    flex: 1;
    text-align: center;
`;

const Logo = styled.h1`
    font-weight: bold;
    ${device({ paddingLeft: "7px", fontSize: "20px" })}
    
`;


const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${device({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
    font-size: 14px;
    font-weight: 500;
    cursor: ${props=>props.type==="user" ? "not-allowed" : "pointer"};
    margin-left: 25px;
    ${device({ fontSize: "12px", marginLeft: "10px" })}
    &:hover {color: ${props=>props.type==="user" ? "#a1140d" : "teal"}};
    ${props=>props.type==="user" ? "10px 0px" : "30px 0px"};
`;

const Navbar = () => {
    const quantity = useSelector(state=> state.cart.quantity);
    const user = useSelector((state)=> state.user.currentUser);
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState("");

    const handleClick = (e) => {
        dispatch(clearCart());
        dispatch(resetCart());
        logout(dispatch);
        window.location.href = "/";
    }

    const search = () => {
        window.location.href = "/search?q=" + searchKeyword;
    }

    return (

        <Container>
            <Wrapper>
                <Left>
                <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}><Language>EN</Language></Link>
                    <SearchContainer>
                        <Input placeholder="Search" onChange={(e)=> setSearchKeyword(e.target.value)}  onKeyPress={(event) => {
                            if (event.code === "Enter" || event.code === "NumpadEnter") { search() }}
    }/>
                     <Search style={{color: "gray", fontSize: 16, cursor: "pointer", "paddingLeft": "10px"}} onClick={()=> search()}/>  
                    </SearchContainer>
                </Left>
                <Center><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}><Logo>KARTFLIP.</Logo></Link></Center>
                <Right>
                { !user && <Link to="/signup" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Register</MenuItem>
                </Link>}
                { !user && <Link to="/signin" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Sign In</MenuItem>
                </Link>}
                { user && !user.isAdmin && <Link to="/admin" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem type="user">Admin</MenuItem>
                </Link>} 
                {user && user.isAdmin && <Link to="/admin" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Admin</MenuItem>
                </Link>}
                { user && <Link to="/account" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Profile</MenuItem>
                </Link>}
                { user && <Link to="/orders" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Orders</MenuItem>
                </Link>}
                { user && <Link to="/wishlist" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Wishlist</MenuItem>
                </Link>}
                { user && <Link to="/wallet" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Duperkoin</MenuItem>
                </Link>}
                { user && <Link to="/cart" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>
                        <Badge badgeContent={quantity} color="primary">
                            <ShoppingCartOutlined/>
                        </Badge>
                    </MenuItem>
                    </Link>}
                { user && 
                <MenuItem>
                    <LogoutIcon onClick={handleClick}/>
                </MenuItem>
                }
                </Right>
            </Wrapper>
        </Container>
    )
}

export default Navbar
