import styled from 'styled-components';
import { device } from '../adjust';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { publicRequest } from '../requests';
import { useSelector } from "react-redux"; 

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${device({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    width: 200px;
    border-radius: 25%;
    padding: 20px;
`;

const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${props=>props.color};
`;

const ColorContainer = styled.div`
    display: flex;
`
const ProductSize = styled.span``;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;

const Icontainer = styled.div`
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
`;
const ProductList = ({item}) => {

    const access_token = useSelector((state)=> state.user.currentUser && state.user.currentUser.access_token);

    const deleteProduct = async (productId)=> {
        try{
            const res = await publicRequest.delete("/products/" + productId, {headers: {'access-token': access_token}});
            console.log(res.data);
            window.location.reload();
        }catch{}
    };
    return (
        <>
                  <Product>
                            <ProductDetail>
                                <Image src={item.image}/>
                                <Details>
                                    <ProductName><b>Product: </b>{item.title}</ProductName>
                                    <ProductId><b>ID: </b>{item._id}</ProductId>
                                    <ColorContainer><b>Color:&ensp;</b><ProductColor color={item.color? item.color[0]: null}/></ColorContainer>
                                    <ProductSize><b>Size: </b>{item.size? item.size[0]: null}</ProductSize>
                                </Details>
                            <Icontainer>
                                <IconButton aria-label="delete" size="large" onClick={() => deleteProduct(item._id)}><DeleteIcon/></IconButton>
                            </Icontainer>
                            </ProductDetail>
                </Product>  
                <Hr/> 
                </>      
    )
}

export default ProductList
