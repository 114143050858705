import styled from "styled-components"
import AdminNavbar from '../components/AdminNavbar'


const Container = styled.div``;

const Admin = () => {
    return (
        <Container>
            <AdminNavbar/>
        </Container>
    )
}
export default Admin
