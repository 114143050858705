import { useState, useEffect } from 'react';
import styled from "styled-components";
import { device } from "../adjust";
import Navbar from "../components/Navbar";
import Announcement from '../components/Announcement'
import { useSelector, useDispatch } from "react-redux";
import { publicRequest } from "../requests";
import { Alert } from '@mui/material';
import { AlertTitle } from '@mui/material';
import { refreshUserBalance } from "../redux/userRedux"

const Div = styled.div`
`;

const Container = styled.div`
    width: 100vw;
    height: %;
    background: url("");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
`;

const Wrapper = styled.div`
    width: 33%;
    padding: 20px;
    background-color: white;
    ${device({ width: "75%" })}
`;

const ButtonWrapper = styled.div`
    width: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${device({ width: "75%" })}
`;

const Title = styled.h1`
    font-size: 22px;
    font-weight: 300;
    padding: 15px;
`;


const KoinValue = styled.span`
    font-size: 13px;;
    font-weight: 500;
`;

const Button = styled.button`
    width: 25%;
    border: none;
    padding: 15px 2px;
    margin: 15px;
    background-color: black;
    color: white;
    cursor: pointer;
    font-weight: 600;
    margin-top: 25px;
`;

const Duperkoin = () => {
    let [balance] = useState("");
    const fiveHundredKoinz = "/product/62838550c3f10625f009d39f/";
    balance = useSelector((state)=> state.user.koinBalance);
    const user_id = useSelector((state)=> state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser.access_token);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(async () =>  {
            const res = await publicRequest.post("/koin/balance/" + user_id, {}, {headers: {'access-token': access_token}});
            const balance = res.data.balance;
            dispatch(refreshUserBalance({ balance: balance }));
        }, 2000);
        return () => clearInterval(timer);
       }, [user_id, access_token, dispatch]);
    
    return (
        <Div>
            <Announcement/>
            <Navbar/>
            <Container>
            <Wrapper>
                <Title>
                    Your Duperkoin wallet:
                </Title>
                <Alert severity="success">
                    You earned <strong>500 koinz</strong> as a signup bonus!
                </Alert>
                <br/>
                <Alert severity="success">
                    <AlertTitle>Balance:</AlertTitle>
                        Your koin balance is <strong>{balance}.</strong><br/><br/>
                    <KoinValue>
                        (1 koin = 1 INR)
                    </KoinValue>
                </Alert>
                <ButtonWrapper>
                <Button onClick= {() => window.location.href = fiveHundredKoinz}>Buy 500 koinz</Button>
                </ButtonWrapper>
            </Wrapper>
            </Container>
        </Div>
    )
}

export default Duperkoin
