import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import { device } from "../adjust";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import { useState, useEffect } from 'react';
import { publicRequest } from "../requests";
import { useHistory, Link } from "react-router-dom";
import { increaseProduct, decreaseProduct, couponDiscount, koinDiscount, clearCart, clearCouponDiscount } from "../redux/cartRedux";
import { useDispatch } from "react-redux";
import { refreshUserBalance } from "../redux/userRedux"

const STRIPE_KEY = "pk_test_51LCeOTSAnlWGB132nMVOe7agn3Eus0NpdoKz1M6AYNf9W1BxY8lY0UDzkXEANPqJ27mEWfrtlwq45BE72X0ukUCN00Cxt8Nnu4";
const Container = styled.div``;

const Wrapper = styled.div`
    padding: 20px;
    ${device({ padding: "10px" })}
`;

const Title = styled.h1`
    font-weight: 300;
    text-align: center;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

const TopButton = styled.button`
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    border: ${props=>props.type === "filled" && "none"};
    background-color: ${props => props.type === "filled" ? "black" : "transparent"};
    color: ${props=>props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
${device({ display: "none" })}
`;

const TopText = styled.span`
    text-decoration: underline;
    cursor: pointer;
    margin: 0px 10px;
`;


const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${device({ flexDirection: "column" })}
`;

const Info = styled.div`
    flex: 3;
`;

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    ${device({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
`;

const Image = styled.img`
    width: 200px;
`;

const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: solid;
    background-color: ${props=>props.color};
`;

const ColorContainer = styled.div`
    display: flex;
`

const ProductSize = styled.span``;

const PriceDetail = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ProductAmount = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const Amount = styled.div`
    font-size: 24px;
    margin: 5px;
    ${device({ margin: "5px 15px" })}
`;
const Price = styled.div`
    font-size: 30px;
    font-weight: 200;
    ${device({ marginBottom: "20px" })}
`;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;
const Summary = styled.div`
    flex: 1;
    border: 0.5px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    height: ${props=>props.status==="success" ? "470px" : "420px;"};
    background-color: white;
`;
 
const SummaryTitle = styled.h1`
    font-weight: 200;
`;

const SummaryItem = styled.div`
    margin: ${props=>props.type==="input" ? "10px 0px" : "30px 0px"};
    display: ${props=>props.type==="default" ? "none" : "flex"};
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: ${props=>props.type==="total" && "500"};
    font-size: ${props=>props.type==="total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const CouponResponseMessage = styled.p`
    margin-top: 7px;
    margin-right: 5px;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
    color: ${props=>props.status==="success" ? "teal" : "#a1140d"};
`;

const Cursor = styled.div`
    cursor: pointer;
`;
const Button = styled.button`
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: black;
    color: white;
    font-weight: 600;
`;

const Nothing = styled.div``;

const Input = styled.input`
    border: none;
    padding: 0px 13px;
    width: 100%;
    outline: none;
`;

const InputContainer = styled.div`
    width: 50%;
    padding: 3px;
    height: 30px;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    border: ${props=>props.status==="success" ? "1.5px solid teal" : "1px solid lightgray"};
    ${device({ width: "40%" })}
`;

const ApplyCouponText = styled.span`
    font-size: 12px;
    font-weight: 500;
    margin: auto;
    padding: 5px;
    cursor: pointer;
    color: ${props=>props.type==="Apply" ? "teal" : "#a1140d"};
`;

const Cart = () => {


    let [koin_balance] = useState("");
    koin_balance = useSelector((state)=> state.user.koinBalance);
    const user_address = useSelector((state)=> state.user.currentUser.address);
    const user_pincode = useSelector((state)=> state.user.currentUser.pincode);

    const user_id = useSelector((state)=> state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser.access_token);

    const cart = useSelector((state) => state.cart);

    const [coupon, setCoupon] = useState("");
    const [couponStatus, setcouponStatus] = useState("");
    const [couponMessage, setcouponMessage] = useState("");

    const [isDisabled, setisDisabled] = useState("");
    const [couponAction, setcouponAction] = useState("Apply");
    
    const [stripeToken, setStripeToken] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();


    const handleClick = (index, action)=>{
        if (action === "increase")
        {
            dispatch(increaseProduct({ index }));
            updateKoinDiscount();
        }
        else{
            dispatch(decreaseProduct({ index }));
            updateKoinDiscount();
        }
    }
    const onToken = (token)=>{
        setStripeToken(token);
    };
    useEffect(() =>{
        const makeRequest = async () =>{
            try{
                const res = await publicRequest.post("/checkout/payment", {
                    tokenId: stripeToken.id,
                    amount: cart.total * 100,
                }, {headers: {'access-token': access_token}});

                if (res.data.object === "payment_intent"){
                    const products = cart.products.map((product)=> ({
                        productId: product._id, quantity: product.quantity
                    }))

                    const koin_res = await publicRequest.post("/koin/redeem/" + user_id, {
                        amount: cart.koin_discount,
                    }, {headers: {'access-token': access_token}});

                    if (koin_res.status === 200 && koin_res.data.status === "success")
                    {
                        const res = await publicRequest.post("/orders", {
                            userId: user_id,
                            products: products,
                            amount: cart.total,
                            address: user_address,
                            pincode: user_pincode,
                            status: "order processing.."
                        }, {headers: {'access-token': access_token}});
    
                        if (res.status === 200)
                        {
                            dispatch(clearCart());
                            history.push("/success", {data: res.data});
                        }
                        else{
                            history.push("/failure", {data: res.data});
                        }
                    }
                }
                else{
                    history.push("/failure", {data: res.data});
                }
            } catch{}
        };
        stripeToken && cart.total >=1 &&  makeRequest();
    }, [stripeToken, cart.total, history, access_token, cart.products, cart.koin_discount, user_address, user_id, user_pincode, dispatch]);


    useEffect(() => {
        const timer = setInterval(async () =>  {
            const res = await publicRequest.post("/koin/balance/" + user_id, {}, {headers: {'access-token': access_token}});
            const balance = res.data.balance;
            dispatch(refreshUserBalance({ balance: balance }));
        }, 3000);
        return () => clearInterval(timer);
       }, [user_id, access_token, dispatch]);


       useEffect(() => {
        const updateCartContext = async () => {
            const products = cart.products.map((product)=> ({
                productId: product._id, quantity: product.quantity
            }));
            if (products.length === 0)
            {
                await publicRequest.post("/cart/reset", {
                    userId: user_id,
                }, {headers: {'access-token': access_token}});
            }
            await publicRequest.post("/cart", {
                userId: user_id,
                products: products,
            }, {headers: {'access-token': access_token}});
        };
        (user_id !== "") && updateCartContext();
      }, [cart.products, user_id, access_token]);


    const updateKoinDiscount = () =>  {
            dispatch(koinDiscount({ balance: koin_balance }));
        };


    const applyCoupon = async () =>{

        if (cart.products.length === 0){
            setcouponStatus("failure");
            setcouponMessage("Your cart is empty");
            return;
        }
        if (coupon.length === 0){
            setcouponStatus("failure");
            setcouponMessage("Please enter a valid coupon code");
            return;
        }
        try{ 
        const res = await publicRequest.post("/coupon/apply", {
            coupon: coupon, userId: user_id
            }, {headers: {'access-token': access_token}});
        if (res.data.status === "success"){
            dispatch(couponDiscount({ discount: Number(res.data.value) }));
            setcouponStatus("success");
            setcouponMessage("Coupon applied successfully");
            setisDisabled("disabled");
            setcouponAction("Remove");
        }
        else if(res.data.status === "limit_exceeded"){
            setcouponStatus("failure");
            setcouponMessage("You've already applied a coupon");
        }
        else{
            setcouponStatus("failure");
            setcouponMessage("The coupon you've applied is invalid. Try HACKTHEPLANET for a flat 300 INR off.");
        }
    }
    catch{}
    };

    const removeCoupon = async () => {
        await publicRequest.post("/cart/clearcoupon", {
            userId: user_id,
        }, {headers: {'access-token': access_token}});
        dispatch(clearCouponDiscount());
        setisDisabled("");
        setcouponAction("Apply");
        setcouponStatus("");
        setcouponMessage("");
    }

    return (
        <Container>
            <Navbar/>
            <Announcement/>
            <Wrapper>
                <Title>Your cart</Title>
                <Top>
                <Link to="/products/all" style={{ color: 'inherit', textDecoration: 'inherit'}}><TopButton>CONTINUE SHOPPING</TopButton></Link>
                    <TopTexts>
                        <TopText>Shopping Cart ({cart.quantity})</TopText>
                        <TopText><Link to="/wishlist" style={{ color: 'inherit', textDecoration: 'inherit'}}>Wishlist (0)</Link></TopText>
                    </TopTexts>
                    <StripeCheckout
                        name="KartFlip" billingAddress shippingAddress
                        description={`Your total is INR ${cart.total}`}
                        currency="INR"
                        amount={cart.total*100}
                        token={onToken}
                        stripeKey={STRIPE_KEY}
                        >
                        <TopButton type="filled">CHECKOUT</TopButton>
                        </StripeCheckout>
                </Top>
                <Bottom>
                    <Info>
                {cart.products.map((product, index)=> (<Nothing key={index}><Product>
                            <ProductDetail>
                                <Image src={product.image}/>
                                <Details>
                                    <ProductName><b>Product: </b>{product.title} </ProductName>
                                    <ProductId><b>ID: </b>{product._id}</ProductId>
                                    <ColorContainer><b>Color:&ensp;</b><ProductColor color={product.color}/></ColorContainer>
                                    <ProductSize><b>Size: {product.size} </b></ProductSize>
                                </Details>
                            </ProductDetail>
                            <PriceDetail>
                                <ProductAmount>
                                    <Cursor>
                                    <Remove onClick={() => handleClick(index, "decrease")}/> 
                                    </Cursor>
                                    <Amount>{product.quantity}</Amount>
                                    <Cursor>
                                    <Add onClick={() => handleClick(index, "increase")} />
                                    </Cursor>
                                </ProductAmount>
                                <Price>INR {product.price * product.quantity}</Price>
                            </PriceDetail>
                        </Product>
                        <Hr/></Nothing>
                        ))}
                    </Info>
                    <Summary status={couponStatus}>
                        <SummaryTitle>Order Summary</SummaryTitle>
                        <SummaryItem>
                            <SummaryItemText>Subtotal: </SummaryItemText>
                            <SummaryItemPrice>INR {cart.sub_total} </SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>Shipping: </SummaryItemText>
                            <SummaryItemPrice>INR {cart.shipping}</SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>Koins Redeemed: </SummaryItemText>
                            <SummaryItemPrice> - INR {cart.koin_discount}</SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem type="input">
                            <SummaryItemText>Coupon: </SummaryItemText>
                            <InputContainer status={couponStatus}>
                                <Input placeholder="Coupon" onChange={(e)=> setCoupon(e.target.value)} disabled={isDisabled}/>
                                <ApplyCouponText onClick={() => couponAction === "Apply" ? applyCoupon(): removeCoupon()} type={couponAction}>{couponAction}</ApplyCouponText>
                            </InputContainer>
                        </SummaryItem>
                        <CouponResponseMessage status={couponStatus}>{couponMessage}</CouponResponseMessage>
                        <SummaryItem type={cart.discount > 0 ? "": "default"}>
                            <SummaryItemText>Coupon Discount: </SummaryItemText>
                            <SummaryItemPrice> - INR {cart.discount}</SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem type="total">
                            <SummaryItemText>Total: </SummaryItemText>
                            <SummaryItemPrice>INR {cart.total}</SummaryItemPrice>
                        </SummaryItem>
                        <StripeCheckout
                        name="KartFlip" billingAddress shippingAddress
                        description={`Your total is INR ${cart.total}`}
                        currency="INR"
                        amount={cart.total*100}
                        token={onToken}
                        stripeKey={STRIPE_KEY}
                        >
                        <Button>CHECKOUT</Button>
                        </StripeCheckout>
                    </Summary>
                </Bottom>
            </Wrapper>
            <Footer/>
        </Container>
    )
}

export default Cart