import { useState, useEffect } from 'react';
import styled from "styled-components";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { Rating, Paper, Grid, Avatar } from '@mui/material';
import { Send } from "@material-ui/icons";
import { publicRequest } from "../requests";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from "react-redux";


const ReviewContainer = styled.div`
    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;
    height: 400px;
    background-color: white;
    display: flex;
`;

const PostReview = styled.div`
    border: none;
    border-radius: 5px;
    padding-right: 20px;
    width: 44vw;
    background-color: white;
    display: flex;
    flex-direction: column;
`;

const CustomerReviewsWrapper = styled.div`
    border: none;
    border-radius: 5px;
    padding-left: 20px;
    width: 44vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const CustomerComments = styled.div`
`;

const RRHeader = styled.h3`
`;

const MiniWrapper = styled.div`
    margin: 20px;
`;

const ReviewDescWrapper = styled.div`
    margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Button = styled.button`
border: none;
width: 100px;
background-color: teal;
color: white;
cursor: pointer;
padding: 5px;
`;

const ReviewTitle = styled.h4`
    margin-right: 10px;
    text-align: left;
`;

const ReviewDescription = styled.p`
    text-align: left;
    margin-top: 18px;
`;

const ReviewUser = styled.p`
    text-align: left;
    color: gray;
    margin-top: 10px;
`;

const RRHeaderWrapper = styled.div`
    display: flex;
`;
const Reviews = () => {

const location = useLocation();
const productId = location.pathname.split("/")[2];
const [reviewPosted, setreviewPosted] = useState(false);
const access_token = useSelector((state)=> {
  if(state.user.currentUser){
      return state.user.currentUser.access_token;
  }
      return "";
});

const postReview = async (url)=> {
        try{
            const res = await publicRequest.post("/products/review/submit", {rating, title, desc, productId}, {headers: {'access-token': access_token}});
            if (res.status === 200) {
              setreviewPosted(true);
              window.location.reload();
            }
        }catch{}
    };

const [rating, setRating] = useState(0);
const [title, setTitle] = useState("");
const [desc, setDesc] = useState("");
const [comments, setComments] = useState([]);

useEffect(()=> {
  const getReviews = async (url)=> {
    try{
        const res = await publicRequest.post("/products/reviews", {productId});
        setComments(res.data.response);
    }catch{}
  };
  getReviews();
}, [productId])

  return (

    <ReviewContainer>

    <PostReview>
        <RRHeader>Rate this product</RRHeader>
        <MiniWrapper>
        <Rating 
            name="no-value" 
            value={rating} 
            size="large" 
            onChange={(e) => {
            setRating(Number(e.target.value));
        }}/>
        </MiniWrapper>
        <RRHeader>Review this product</RRHeader>
    <MiniWrapper>
        <ReviewDescWrapper>
            <TextField
            id=""
            label="Review title"
            fullWidth
            size="small"
            InputLabelProps={{style: {fontSize: 14}}}
            onChange= {(e)=> setTitle(e.target.value)}
            />
        </ReviewDescWrapper>
        <ReviewDescWrapper>
            <TextField
            id=""
            label="Your review"
            multiline
            rows={5}
            fullWidth
            InputLabelProps={{style: {fontSize: 14}}}
            onChange= {(e)=> setDesc(e.target.value)}
            />
        </ReviewDescWrapper>
        <ButtonWrapper>
        <Button onClick={() => postReview()}>
        {reviewPosted ? <CheckCircleIcon/> : <Send/>}
        </Button>
        </ButtonWrapper>
    </MiniWrapper>
    </PostReview>


    <Divider orientation="vertical" flexItem />


    <CustomerReviewsWrapper>
    <RRHeader>Customer Reviews</RRHeader>
    <CustomerComments>
    
    {comments && comments.slice(0).reverse().map((element, index)=> (
        <Paper style={{ padding: "40px 20px", marginTop: 20 }} key={index}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar src=""/>
          </Grid>
          <Grid justifyContent="left" item xs zeroMinWidth>
            <RRHeaderWrapper>
            <ReviewTitle>{element.title}</ReviewTitle>
            <Rating 
              value={Number(element.rating)} 
              size="small"
              readOnly/>
            </RRHeaderWrapper>
            <ReviewDescription><span dangerouslySetInnerHTML={{"__html": decodeURIComponent(element.desc)}}/></ReviewDescription>
            <ReviewUser>Kartflip Customer - 1 minute ago</ReviewUser>
          </Grid>
        </Grid>
      </Paper>)
        )}
              
      </CustomerComments>
    </CustomerReviewsWrapper>
    </ReviewContainer>
  )
}

export default Reviews