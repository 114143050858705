import { useState, useEffect } from 'react';
import styled from "styled-components";
import { device } from "../adjust";
import Navbar from "../components/Navbar";
import Announcement from '../components/Announcement'
import Footer from '../components/Footer';
import Newsletter from '../components/Newsletter';
import { useSelector, useDispatch } from "react-redux";
import { publicRequest } from "../requests";
import { changeProfilePic } from "../redux/userRedux"

const Div = styled.div`
`;

const Container = styled.div`
    width: 100vw;
    height: %;
    background: url("");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
`;

const Wrapper = styled.div`
    width: 33%;
    padding: 20px;
    background-color: white;
    ${device({ width: "75%" })}
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 300;
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
`;

const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 10px;
`;

const Button = styled.button`
    width: 25%;
    border: none;
    padding: 15px 15px;
    background-color: black;
    color: white;
    cursor: pointer;
    font-weight: 600;
    margin-top: 25px;
`;

const ImageInfo = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
    `;

const FileInput = styled.input.attrs({type: 'file', name: 'profile_pic', accept: 'image/*'})`
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
    `;

const ImageText = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: #e6e4df;
    text-decoration: underline;
`;

const ImageContainer = styled.div`
    flex: 1;
    margin: 25px 0px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &:hover ${ImageInfo}{
        opacity: 1;
    }
`;

const Circle = styled.div`
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: white;
    position: absolute;
`;

const Image = styled.img`
    height: 100%;
    z-index: 2;
    border-radius: 50%;
`;



const Myaccount = () => {

    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setcPassword] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPin] = useState("");
    const [profilePic, setProfilePic] = useState("");

    const user_id = useSelector((state)=> state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser.access_token);
    const image_url = useSelector((state)=> state.user.imageUrl);

    const dispatch = useDispatch();
    
    useEffect(()=> {
        setProfilePic(image_url);
    }, [image_url]);
    
    const updateUserDetails = async ()=> {
            try{
                const res = await publicRequest.put("/users/" + user_id, {fName, lName, username, email, address, pincode}, {headers: {'access-token': access_token}});
                res.data.response.first_name && setfName(res.data.response.first_name);
                res.data.response.last_name && setlName(res.data.response.last_name);
            }catch{}
        };

    const updateUserPass = async ()=> {
            try{
                const res = await publicRequest.put("/users/" + user_id, {password, cPassword}, {headers: {'access-token': access_token}});
                console.log(res.data);
                alert("Password Updated!")
            }catch{}
        };

    const updateProfilePic = async (e)=> {
            try{
                const file = e.target.files[0];
                let formData = new FormData();
                formData.append("profile_pic", file);
                const res = await publicRequest.post("/upload/image/" + user_id, formData, {headers: {'access-token': access_token, 'Content-Type': 'multipart/form-data'}});
                dispatch(changeProfilePic({ profilePic: res.data.url }));
            }catch{}
        };

    const handleClickUserDetails = (e) => {
        e.preventDefault();
        updateUserDetails();
    }


    const handleClickUserPass = (e) => {
        e.preventDefault();
        updateUserPass();
    }
    
    return (
        <Div>
            <Announcement/>
            <Navbar/>
            <Container>
            <Wrapper>
                <Title>
                    Your Profile
                </Title>
                <ImageContainer>
                <Circle/>
                <Image src={profilePic} height="100px" width="100px"/>
                <ImageInfo>
                    <ImageText>Change</ImageText>
                    <FileInput onChange={updateProfilePic}/>
                </ImageInfo>
                </ImageContainer>
                <Form>
                    <Input placeholder="first name" onChange={(e)=> setfName(e.target.value)}/>
                    <Input placeholder="last name" onChange={(e)=> setlName(e.target.value)}/>
                    <Input placeholder="username" onChange={(e)=> setUsername(e.target.value)}/>
                    <Input placeholder="email" onChange={(e)=> setEmail(e.target.value)}/>
                    <Input placeholder="address" onChange={(e)=> setAddress(e.target.value)}/>
                    <Input placeholder="pincode" onChange={(e)=> setPin(e.target.value)}/>
                    <Button onClick={handleClickUserDetails}>Apply Changes</Button>
                </Form>
            </Wrapper>
            <Wrapper>
                <Title>
                    Security Settings
                </Title>
                <Form>
                    <Input placeholder="password" type="password" onChange={(e)=> setPassword(e.target.value)}/>
                    <Input placeholder="confirm password" type="password" onChange={(e)=> setcPassword(e.target.value)}/>
                    <Button onClick={handleClickUserPass}>Apply Changes</Button>
                </Form>
            </Wrapper>
            </Container>
            <Newsletter/>
            <Footer/>
        </Div>
    )
}

export default Myaccount
