import { useState, useEffect } from 'react';
import styled from "styled-components";
import { device } from "../adjust";
import Navbar from "../components/Navbar";
import Announcement from '../components/Announcement'
import WishlistItem from "../components/WishlistItem";
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';
import { useSelector } from 'react-redux';
import { publicRequest } from '../requests';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

const Div = styled.div`
`;

const Container = styled.div`
    width: 100vw;
    height: %;
    background: url("");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
`;

const ItemWrapper = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    padding: 20px;
    ${device({ width: "75%" })}
`;

const Title = styled.h1`
    margin: 30px;
    padding: 20px;
    font-size: 24px;
    font-weight: 300;
`;

const Emptiness = styled.div`
    font-weight: 350;
    display: flex;
    justify-content: center;
    color: gray;
`;

const Wishlist = () => {

    const user_id = useSelector((state)=> state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser.access_token);
    const [products, setProducts] = useState([]);

    useEffect(()=>{
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get("/wishlist/fetch/" + user_id, {headers: {'access-token': access_token}});
                setProducts(res.data.response.products); 
            }catch(err){

            }
        };
        getProducts()
    }, [access_token, user_id])

    return (
        <Div>
            <Announcement/>
            <Navbar/>
            <Container>
            <Title>
                Your Wishlist <FavoriteOutlinedIcon color="error"/>
            </Title>
            <ItemWrapper>
            {products.length === 0 ? <Emptiness>You haven't added any items to the wishlist yet</Emptiness> : null}
            {products.map((item, index)=> (
                    <WishlistItem item = {item} key={index}/>
                ))}
            </ItemWrapper>
            </Container>
            <Newsletter/>
            <Footer/>
        </Div>
    )
}

export default Wishlist
