import styled from "styled-components"
import Product from "./Product";
import { useState, useEffect } from 'react';
import { publicRequest } from "../requests";
import { useSelector } from "react-redux";

const Container = styled.div`
    padding: 20px;
    display: inline-flex;
    flex-wrap: wrap;
`;
const Products = ({category, filters, sort}) => {

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const user_id = useSelector((state)=> state.user.currentUser && state.user.currentUser.user_id);
    const access_token = useSelector((state)=> state.user.currentUser && state.user.currentUser.access_token);

    useEffect(()=>{
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get(category ? `/products?category=${category}` : "/products/");
                setProducts(res.data.response); 
            }catch(err){

            }
        };
        getProducts()
    }, [category]);

    useEffect(()=> {
        const getWishlist = async ()=>{
            try{
                const res = await publicRequest.get("/wishlist/fetch/" + user_id, {headers: {'access-token': access_token}});
                setWishlist(res.data.response.products);
            }catch(err){
            }
        }
        if (user_id){
            getWishlist();
        }
    }, [user_id, access_token]);

    useEffect(()=> {
        category && setFilteredProducts(products.filter(item => Object.entries(filters).every(([key, value]) => item[key].includes(value))));
    }, [products, category, filters]);

    useEffect(()=> {
        if(sort === "newest"){
            setFilteredProducts((prev)=> [...prev].sort((a,b)=> a.createdAt - b.createdAt));
        } else if (sort === "asc"){
            setFilteredProducts((prev)=> [...prev].sort((a,b)=> a.price - b.price));
        }
        else {
            setFilteredProducts((prev)=> [...prev].sort((a,b)=> b.price - a.price));
        }
    }, [sort]);

    return (
        <Container>
            {category ? filteredProducts.map((item, index) =>
            (
                    <Product item = {item} wishlist={wishlist} key={index}/>
            )) : products.map((item, index)=> (
                <Product item = {item} wishlist={wishlist} key={index}/>
        ))}
        </Container>
    )
}

export default Products