import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState:{
        products: [],
        quantity: 0,
        shipping: 0,
        discount: 0,
        koin_discount: 0,
        sub_total: 0,
        total: 0
    },
    reducers: {
        addProduct: (state, action) => {
            state.quantity += 1;
            state.products.push(action.payload);
            state.shipping = 60;
            state.discount = 0;
            state.sub_total += action.payload.price * action.payload.quantity;
            state.total += action.payload.price * action.payload.quantity + state.shipping;
        },
        increaseProduct: (state, action) => {
            state.products[action.payload.index].quantity += 1;
            state.products[action.payload.index].total += state.products[action.payload.index].price * state.products[action.payload.index].quantity;
            state.sub_total += state.products[action.payload.index].price;
            state.total += state.products[action.payload.index].price;
        },
        decreaseProduct: (state, action) => {
            if (state.products[action.payload.index].quantity > 1 )
            {
                state.products[action.payload.index].quantity -= 1;
                state.products[action.payload.index].total = state.products[action.payload.index].price * state.products[action.payload.index].quantity;
                state.sub_total -= state.products[action.payload.index].price;
                state.total -= state.products[action.payload.index].price;
            }
            else if (state.products[action.payload.index].quantity === 1 )
            {
                state.quantity -= 1;
                state.products[action.payload.index].total -= state.products[action.payload.index].price * state.products[action.payload.index].quantity;
                state.sub_total -= state.products[action.payload.index].price;
                state.total -= state.products[action.payload.index].price;
                const index = state.products.indexOf(state.products[action.payload.index]);
                if (index > -1) {
                    state.products.splice(index, 1);
                }
                if (state.quantity === 0){
                    state.shipping = 0;
                    state.discount = 0;
                }
            }
        },

        couponDiscount: (state, action) => {
            if (state.total > action.payload.discount)
            {
                state.discount += action.payload.discount;
                state.total -= action.payload.discount;
            }
        },

        clearCouponDiscount: (state, action) => {
            if (state.discount > 0)
            {
                state.total += state.discount;
                state.discount = 0;
            }
        },

        koinDiscount: (state, action) => {
            if (action.payload.balance > Math.floor(state.sub_total/10) )
            {
                state.koin_discount = Math.floor(state.sub_total/10);
                state.total = state.sub_total + state.shipping - state.koin_discount;
            }
            else{
                state.koin_discount = action.payload.balance;
                state.total = state.sub_total + state.shipping - state.koin_discount;
            }
        },

        clearCart: (state) => {
            state.quantity = 0;
            state.products = [];
            state.shipping = 0;
            state.discount = 0;
            state.sub_total = 0;
            state.total = 0;
            if (state.koin_discount > 0 )
            {
                state.koin_discount = 0;
            }
        }
    }
});

export const {addProduct, increaseProduct, decreaseProduct, clearCart, couponDiscount, clearCouponDiscount, koinDiscount} = cartSlice.actions;
export default cartSlice.reducer;