export const sliderItems = [
    {
        id: 1,
        img: "https://rukminim1.flixcart.com/image/880/1056/kzx1a4w0/shirt/v/5/v/m-gsshrt1643grymlg-gritstones-original-imagbthyhk5nn52k.jpeg?q=50",
        title: "Summer Sale",
        desc: "Don't Compromise on Style! Get flat 300 off for new arrivals.",
        bg: "f5fafd"
    },
    {
        id: 2,
        img: "/uploads/women.jpg",
        title: "Hot Collections",
        desc: "Don't Compromise on Style! Get flat 300 off for new arrivals.",
        bg: "fcf1ed"
    },
    {
        id: 3,
        img: "/uploads/kids.jpg",
        title: "Trending Products",
        desc: "Don't Compromise on Style! Get flat 300 off for new arrivals.",
        bg: "f5fafd"
    }    
];

export const categories = [
    {   id: 1, 
        title: "Men's Collection", 
        img: "/uploads/men.jpg",
        category: "men"
    },
    { 
        id: 2,
        img: "/uploads/women_collection.jpg",
        title: "Trends For Women",
        category: "women"
    },
    { 
        id: 3,
        img: "/uploads/kids_top_picks.jpg",
        title: "Top Picks For Kids",
        category: "kids"
    }
];

export const popularProducts = [
    {
        id: 1,
        img: "https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png"
    },

    {
        id: 2,
        img: "https://cdn.shopify.com/s/files/1/0101/4832/products/Angela_Natural_Tee.png?v=1606780388"
    },
    {
        id: 3,
        img: "https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png"
    },
    {
        id: 4,
        img: "https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png"
    },
    {
        id: 5,
        img: "https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png"
    },
    {
        id: 6,
        img: "https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png"
    },
    {
        id: 7,
        img: "https://purepng.com/public/uploads/large/purepng.com-black-t-shirtclothingblack-t-shirtfashion-dress-shirt-black-cloth-tshirt-631522326884bzr0p.png"
    },
    {
        id: 8,
        img: "https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png"
    }
];