import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../adjust';
import { publicRequest } from '../requests';
import Chip from '@mui/material/Chip';
import SellIcon from '@mui/icons-material/Sell';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${device({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    width: 200px;
    border-radius: 25%;
    padding: 20px;
`;

const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ProductName = styled.span`
    font-weight: 500;
    margin: 10px;    
`;

const ProductId = styled.span`
    margin: 10px;
`;

const QuantityContainer = styled.div`
    margin: 10px;
    display: flex;
`;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;

const Icontainer = styled.div`
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 30px;
`;
const OrderList = ({item}) => {

    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");

    useEffect(()=>{
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get("/products/find/" + item.productId);
                setTitle(res.data.response.title);
                setImage(res.data.response.image);
            }catch{}
        };
        getProducts();
    }, [item])

    return (
        <>
                  <Product>
                            <ProductDetail>
                                <Image src={image}/>
                                <Details>
                                    <ProductName>{title}</ProductName>
                                    <ProductId><b>Product ID: </b>{item.productId}</ProductId>
                                    <QuantityContainer><Chip label={`quantity: ${item.quantity}`} color="info" variant="outlined" icon={<SellIcon fontSize="small"/>} /></QuantityContainer>
                                </Details>
                            <Icontainer>
                                <Chip label="order processing.." color="success" variant="outlined" icon={<HourglassBottomIcon fontSize="small"/>} />
                            </Icontainer>
                            </ProductDetail>
                </Product>  
                <Hr/> 
        </>      
    )
}

export default OrderList
