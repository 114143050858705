import { Facebook, Instagram, MailOutline, Phone, Room, Twitter } from "@material-ui/icons";
import styled from "styled-components"
import { device } from "../adjust";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    ${device({ flexDirection: 'column' })}
`;
const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Logo = styled.h1``;

const Desc = styled.p`
    margin: 20px 0px;
`;
const SocialContainer = styled.div`
    display: flex;
`;
const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;

const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${device({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
`;

const ListItem = styled.li`
    width: 50%;
    margin-bottom: 10px;
`;

const Right = styled.div`
    flex: 1;
    padding: 20px;
    ${device({ backgroundColor: "#fff8f8" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Payment = styled.img`
    width: 40%;
`;


const Footer = () => {
    return (
        <Container>
            <Left>
                <Logo>KARTFLIP.</Logo>
                <Desc>
                KartFlip is an Indian e-commerce company, headquartered in Bangalore, Karnataka, India, and incorporated in Singapore as a private limited company. The company initially focused on online book sales before expanding into other product categories such as consumer electronics, fashion, home essentials, groceries, and lifestyle products.
                </Desc>
                <SocialContainer>
                    <SocialIcon color="3B5999">
                        <Facebook/>
                    </SocialIcon>
                    <SocialIcon color="E4405F">
                        <Instagram/>
                    </SocialIcon>
                    <SocialIcon color="55ACEE">
                        <Twitter/>
                    </SocialIcon>
                </SocialContainer>
            </Left>
            <Center>
                <Title>Useful Links</Title>
                <List>
                    <ListItem><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>Home</Link></ListItem>
                    <ListItem><Link to="/cart" style={{ color: 'inherit', textDecoration: 'inherit'}}>Cart</Link></ListItem>
                    <ListItem><Link to="/products/men" style={{ color: 'inherit', textDecoration: 'inherit'}}>Men Fashion</Link></ListItem>
                    <ListItem><Link to="/products/women" style={{ color: 'inherit', textDecoration: 'inherit'}}>Women Fashion</Link></ListItem>
                    <ListItem><Link to="/products/all" style={{ color: 'inherit', textDecoration: 'inherit'}}>Accessories</Link></ListItem>
                    <ListItem><Link to="/account" style={{ color: 'inherit', textDecoration: 'inherit'}}>My Account</Link></ListItem>
                    <ListItem><Link to="/orders" style={{ color: 'inherit', textDecoration: 'inherit'}}>Order Tracking</Link></ListItem>
                    <ListItem><Link to="/wishlist" style={{ color: 'inherit', textDecoration: 'inherit'}}>Wishlist</Link></ListItem>
                    <ListItem><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>Terms and Conditions</Link></ListItem>
                </List>
            </Center>
            <Right>
                <Title>Contact</Title>
                    <ContactItem>
                        <Room style={{marginRight:"10px"}}/>
                        KartFlip, EVT Campus, Bangalore
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{marginRight:"10px"}}/>
                        +91 9876543210
                    </ContactItem>
                    <ContactItem>
                        <MailOutline style={{marginRight:"10px"}}/>
                        security@kartflip.com
                    </ContactItem>
                    <Payment src="https://i.ibb.co/Qfvn4z6/payment.png"/>
            </Right>
        </Container>
    )
}

export default Footer
