import { Add, Remove } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import styled from "styled-components"
import { device } from "../adjust";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Reviews from "../components/Reviews";
import Newsletter from "../components/Newsletter";
import { useState, useEffect } from 'react';
import { publicRequest } from "../requests";
import { addProduct } from "../redux/cartRedux";
import { koinDiscount } from "../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "@material-ui/icons"
import { Alert } from '@mui/material';
import { AlertTitle } from '@mui/material';


const Container = styled.div``;

const Wrapper = styled.div`
    padding: 50px;
    display: flex;
    ${device({ padding: "10px", flexDirection: "column" })}
`;

const ImgContainer = styled.div`
    flex: 1;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${device({ height: "40vh" })}
`;

const InfoContainer = styled.div`
    flex: 1;
    padding: 0px 50px;
    ${device({ padding: "10px" })}
`;

const Title = styled.h1`
    font-weight: 200;
`;

const Desc = styled.p`
    margin: 20px 0px;
`;

const Price = styled.span`
    font-weight: 100;
    font-size: 40px;
`;

const FilterContainer = styled.div`
    width: 50%;
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    ${device({ width: "100%" })}

`;

const Filter = styled.div`
    display: flex;
    align-items: center;
`;

const FilterTitle = styled.span`
    font-size: 20px;
    font-weight: 200;
`;

const FilterColor = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${props=>props.color};
    margin: 0px 5px;
    cursor: pointer;
    outline: solid;
`;

const FilterSize = styled.select`
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
`;

const FilterSizeOption = styled.option`
`;

const AddContainer= styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${device({ width: "100%" })}
`;

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
`;

const Amount = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
`;

const Button = styled.option`
    padding: 15px;
    border: 2px solid teal;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    &:hover{
        background-color: #f8f4f4
    }
`;

const PriceMatchWrapper = styled.div`
    margin: 70px 0px 0px 0px;
`;

const PriceMatchDesc = styled.p`
    margin: 20px 0px;
    font-size: 13px;
`;

const SSRFMessageWrapper = styled.div`
    width: 65%;
    margin: 40px 0px;
    display: ${props=>props.type === "" && "none"};
`;

const SSRFErrorMessageContainer = styled.p`
    font-size: 14px;
`;

const InputContainer = styled.div`
    width: 65%;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    ${device({ width: "80%" })}
`;

const SSRFActionWrapper = styled.div`
    display: flex;
    ${device({ width: "80%" })}
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
`;
const SSRFButton = styled.button`
    border: none;
    margin-left: 5px;
    background-color: teal;
    color: white;
    cursor: pointer;
    width: 70px;
`;

const Product = () => {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [color, setColor] = useState("");
    const [url, setURL] = useState("");
    const [size, setSize] = useState("");
    const dispatch = useDispatch();
    const user = useSelector((state)=> state.user.currentUser);
    const user_id = useSelector((state)=> {
        if(state.user.currentUser){
            return state.user.currentUser.user_id;
        }
            return "";
    });
    const access_token = useSelector((state)=> {
        if(state.user.currentUser){
            return state.user.currentUser.access_token;
        }
            return "";
    });
    let [koin_balance] = useState("");
    koin_balance = useSelector((state)=> state.user.koinBalance);
    const [SSRFErrorMessage, setSSRFErrorMessage] = useState("");
    const [ssrfStatus, setssrfStatus] = useState("");
    const cart = useSelector((state) => state.cart);

    useEffect(()=> {
        window.scrollTo(0, 0);
        const getProduct = async ()=> {
            try{
                const res = await publicRequest.get("/products/find/" + productId);
                setProduct(res.data.response);
                res.data.response.color && setColor(res.data.response.color[0]);
                res.data.response.size && setSize(res.data.response.size[0]);
            }catch{}
        };
        getProduct();
    }, [productId]);

    useEffect(() => { 
        const updateCartContext = async () => {
            const products = cart.products.map((product)=> ({
                productId: product._id, quantity: product.quantity
            }));
            await publicRequest.post("/cart", {
                userId: user_id,
                products: products,
            }, {headers: {'access-token': access_token}});
        };
        (user_id !== "") && updateCartContext();
      }, [cart.products, user_id, access_token]);


    const priceMatch = async (url)=> {
        try{
            await publicRequest.post("/pricematch/submit/", {url}, {headers: {'access-token': access_token}});
            setSSRFErrorMessage("Unable to parse the response data. Please check the URL you provided.");
            setssrfStatus("error");
        }catch{}
    };

    const handleQuantity = (type) =>{
        if(type === "decrease"){
            quantity > 1 && setQuantity(quantity - 1);
        }
        else{
            setQuantity(quantity + 1);
        }
    };

    const updateKoinDiscount = () =>  {
        dispatch(koinDiscount({ balance: koin_balance }));
    };

    const handleClick = async () => {
        dispatch(addProduct({ ...product, quantity, color, size }));
        updateKoinDiscount();
    }
    return (
        <Container>
            <Navbar/>
            <Announcement/>
            <Wrapper>
                <ImgContainer>
                    <Image src={product.image}/>
                </ImgContainer>
                <InfoContainer>
                    <Title>{product.title}</Title>
                    <Desc>{product.description}</Desc>
                    <Price>{product.price}</Price>
                    <FilterContainer>
                        <Filter>
                            <FilterTitle>Color:</FilterTitle>
                            {product.color &&  product.color.map((c)=>(
                                <FilterColor color={c} key={c} onClick={()=> setColor(c)}/>
                            ))}
                        </Filter>
                        <Filter>
                            <FilterTitle>Size:</FilterTitle>
                            <FilterSize onChange={(e)=> setSize(e.target.value)}>
                                {product.size && product.size.map((s) => (
                                    <FilterSizeOption key={s}>{s}</FilterSizeOption>
                                ))}
                            </FilterSize>
                        </Filter>
                    </FilterContainer>
                    <AddContainer>
                        <AmountContainer>
                            <Remove onClick={() => handleQuantity("decrease")}/>
                            <Amount>{quantity}</Amount>
                            <Add onClick={() => handleQuantity("increase")}/>
                        </AmountContainer>
                        <Button onClick={ user ? handleClick: null}>Add To Cart</Button>
                    </AddContainer>
                    <PriceMatchWrapper>
                    <PriceMatchDesc>Found this product at a better price elsewhere? Enter the URL and we will match the price!</PriceMatchDesc>
                    <SSRFActionWrapper>
                    <InputContainer>
                    <Input placeholder="Enter the URL" onChange={(e)=> setURL(e.target.value)}/>
                    </InputContainer>
                    <SSRFButton onClick={() => priceMatch(url)}><Send/></SSRFButton>
                    </SSRFActionWrapper>
                    </PriceMatchWrapper>
                    <SSRFMessageWrapper type={ssrfStatus}>
                        <Alert severity="error">
                        <AlertTitle>Error:</AlertTitle>
                        <SSRFErrorMessageContainer>
                            {SSRFErrorMessage}
                            </SSRFErrorMessageContainer>
                        </Alert>
                    </SSRFMessageWrapper>
                </InfoContainer>
            </Wrapper>
            <Reviews/>
            <Newsletter/>
            <Footer/>
        </Container>            
    )
}

export default Product
