import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../adjust';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/apiCalls";
import { clearCart } from "../redux/cartRedux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { publicRequest } from "../requests";
import ProductList from "../components/ProductList";


const Container = styled.div`
    background-color: white;
    ${device({ height: "50px" })}

`;

const Wrapper = styled.div`
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${device({ padding: "10px 0px" })}
`;

const Center = styled.div`
    flex: 1;
    text-align: center;
`;

const Logo = styled.h1`
    font-weight: bold;
    ${device({ paddingLeft: "7px", fontSize: "20px" })}
    
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${device({ flex: 2, justifyContent: "center" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const MenuItem = styled.div`
    font-size: 14px;
    font-weight: 500px;
    cursor: pointer;
    margin-left: 25px;
    ${device({ fontSize: "12px", marginLeft: "10px" })}
    &:hover {color: teal;}
`;

const PageWrapper = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Crud = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
`;

const ButtonWrapper = styled.div`
    padding: 20px;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const ProductWrapper = styled.div`
    border: 0.5px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    height: 680px;
    width: 75vw;
    background-color: white;
    display: flex;
    overflow-y: auto;
`;

const Info = styled.div`
    flex: 3;
`;

const AdminNavbar = () => {

    const [productDialogOpen, setProductOpen] = useState(false);
    const [offerDialogOpen, setOfferOpen] = useState(false);
    const [couponDialogOpen, setCouponOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");
    const [description, setDesc] = useState("");
    const [categories, setCategory] = useState("");
    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [price, setPrice] = useState("");
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState("");
    const [banner, setBanner] = useState("");

    const [products, setProducts] = useState([]);

    const access_token = useSelector((state)=> state.user.currentUser && state.user.currentUser.access_token);

    const addProduct = async ()=> {
        try{
            const res = await publicRequest.post("/products/", {title, description, categories, size, color, price, image}, {headers: {'access-token': access_token}});
            console.log(res.data.response);
            handleClose(1);
            window.location.reload();
        }catch{}
    };

    const addCoupon = async ()=> {
        try{
            const res = await publicRequest.post("/coupon/add/", {coupon, discount}, {headers: {'access-token': access_token}});
            console.log(res.data.response);
            handleClose(3);
            window.location.reload();
        }catch{}
    };

    const addBanner = async ()=> {
        try{
            const res = await publicRequest.post("/banner/add/", {banner}, {headers: {'access-token': access_token}});
            console.log(res.data.response);
            handleClose(2);
            window.location.reload();
        }catch{}
    };

    const handleClickOpen = (n) => {
        if (n === 1){
            setProductOpen(true);
        }
        else if (n === 2){
            setOfferOpen(true);
        }
        else{
            setCouponOpen(true);
        }
      };
    
    const handleClose = (n) => {
        if (n === 1){
                setProductOpen(false);
            }
            else if (n === 2){
                setOfferOpen(false);
            }
            else{
                setCouponOpen(false);
            }
      };

    const user = useSelector((state)=> state.user.currentUser);
    const dispatch = useDispatch();
    const handleClick = (e) => {
        logout(dispatch);
        dispatch(clearCart());
        window.location.href = "/";
    }

    useEffect(()=>{
        let isMounted = true;
        const getProducts = async ()=>{
            try{
                const res = await publicRequest.get("/products/", {headers: {'access-token': access_token}});
                if (isMounted){
                    setProducts(res.data.response);
                }
            }catch(err){

            }
        };
        getProducts();
        return () => {
            isMounted = false;
            };
    }, [access_token])

    return (
        <Container>
            <Wrapper>
                <Left></Left>
                <Center><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}><Logo>KARTFLIP ADMIN PANEL.</Logo></Link></Center>
                <Right>
                { user && 
                <MenuItem>
                    <Button variant="outlined" color="error" onClick={handleClick}>Logout</Button>
                </MenuItem>
                }
                </Right>
            </Wrapper>
            <PageWrapper>
                <Crud>
                <ButtonWrapper>
                <Button variant="outlined" size="medium" onClick={() => handleClickOpen(1)}>
                    Add Product
                </Button>
                <Dialog open={productDialogOpen} onClose={() => handleClose(1)}>
                    <DialogTitle>Add Product</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    Enter product details including title, description and other details here:
                    </DialogContentText>
                    <Form>
                    <TextField id="standard-multiline-flexible" label="Product Title" multiline maxRows={4} variant="standard" margin="normal" onChange={(e)=> setTitle(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-search" label="Product Image URL" variant="standard" margin="normal" onChange={(e)=> setImage(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-multiline-static" rows={4} label="Product Description" placeholder="Description" multiline variant="standard" margin="normal" onChange={(e)=> setDesc(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-search" label="Product Category" variant="standard" margin="normal" onChange={(e)=> setCategory(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-multiline-flexible" label="Product Size" multiline variant="standard" margin="normal" onChange={(e)=> setSize(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-multiline-flexible" label="Product Color" multiline variant="standard" margin="normal" onChange={(e)=> setColor(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    <TextField id="standard-multiline-flexible" label="Price in INR" multiline variant="standard" margin="normal" onChange={(e)=> setPrice(e.target.value)} InputLabelProps={{style: {fontSize: 14}}}/>
                    </Form>
                    </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose(1)}>Cancel</Button>
                            <Button onClick={() => addProduct()}>Save</Button>
                        </DialogActions>
                </Dialog>
                </ButtonWrapper>
                <ButtonWrapper>
                <Button variant="outlined" size="medium" onClick={() => handleClickOpen(2)}>
                    Add Banner
                </Button>
                <Dialog open={offerDialogOpen} onClose={() => handleClose(2)}>
                <DialogTitle>Add Banner</DialogTitle>
                <DialogContent>
                <DialogContentText>
                To add announcement/new offers to the top banner, please enter the description here. This will auto-update the banner, once you apply changes.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Announcement"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={(e)=> setBanner(e.target.value)}
                    InputLabelProps={{style: {fontSize: 14}}}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => handleClose(2)}>Cancel</Button>
                    <Button onClick={() => addBanner()}>Save</Button>
                    </DialogActions>
                    </Dialog>
                </ButtonWrapper>
                <ButtonWrapper>
                <Button variant="outlined" size="medium" onClick={() => handleClickOpen(3)}>
                    Add Coupon
                </Button>
                <Dialog open={couponDialogOpen} onClose={() => handleClose(3)}>
                <DialogTitle>Add Coupon</DialogTitle>
                <DialogContent>
                <DialogContentText>
                To add discount coupons to the database, please enter the details here:
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="normal"
                    id="coupon"
                    label="Coupon Value"
                    fullWidth
                    variant="standard"
                    onChange={(e)=> setCoupon(e.target.value)}
                    InputLabelProps={{style: {fontSize: 14}}}
                    />
                <TextField
                    margin="normal"
                    id="discount"
                    label="Discount in INR"
                    fullWidth
                    variant="standard"
                    onChange={(e)=> setDiscount(e.target.value)}
                    InputLabelProps={{style: {fontSize: 14}}}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => handleClose(3)}>Cancel</Button>
                    <Button onClick={() => addCoupon()}>Save</Button>
                    </DialogActions>
                    </Dialog>
                </ButtonWrapper>
                </Crud>
            <ProductWrapper>
                <Info>

                {products && products.map((element, index)=> (
                    <ProductList item ={element} key={index}/>
                    )
                )}

                </Info>
            </ProductWrapper>
            </PageWrapper>

        </Container>
    )
}

export default AdminNavbar
